<template>
  <svg
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
  >
    <path
      d="M19.9031 2.39493C23.9767 1.60188 28.1991 2.00891 32.0364 3.56452C35.8736 5.12014 39.1534 7.75449 41.4609 11.1344C43.7684 14.5144 45 18.4881 45 22.5532C45 23.6342 44.1046 24.5106 43 24.5106C41.8954 24.5106 41 23.6342 41 22.5532C41 19.2624 40.003 16.0456 38.135 13.3094C36.267 10.5733 33.612 8.44072 30.5056 7.18141C27.3993 5.9221 23.9811 5.5926 20.6835 6.23459C17.3858 6.87659 14.3567 8.46123 11.9792 10.7881C9.6017 13.115 7.98261 16.0797 7.32666 19.3072C6.67071 22.5347 7.00737 25.8801 8.29406 28.9203C9.58075 31.9606 11.7597 34.5591 14.5553 36.3874C17.3509 38.2156 20.6377 39.1914 24 39.1914H37.5838L35.7655 37.7953C34.8965 37.128 34.7447 35.8976 35.4264 35.047C36.1082 34.1965 37.3654 34.0479 38.2345 34.7152L43.9705 39.1194C44.5717 39.581 45 40.3018 45 41.1489C45 41.9959 44.5717 42.7167 43.9705 43.1783L38.2345 47.5825C37.3654 48.2498 36.1082 48.1012 35.4264 47.2507C34.7447 46.4001 34.8965 45.1697 35.7655 44.5024L37.5838 43.1063H23V43.083C19.1975 42.9056 15.5087 41.7193 12.333 39.6425C8.8796 37.3841 6.18798 34.1741 4.59854 30.4185C3.0091 26.6629 2.59323 22.5304 3.40352 18.5434C4.21381 14.5565 6.21386 10.8943 9.15076 8.01989C12.0877 5.14548 15.8295 3.18798 19.9031 2.39493Z"
    />
    <path
      d="M17.0254 13.1282C19.2092 12.2429 21.5499 11.7872 23.9137 11.7872C26.2775 11.7872 28.6181 12.2429 30.802 13.1282C32.9859 14.0136 34.9702 15.3112 36.6416 16.9471C37.4227 17.7115 37.4227 18.9509 36.6416 19.7154C35.8606 20.4798 34.5942 20.4798 33.8132 19.7154C32.5132 18.443 30.9698 17.4337 29.2713 16.7451C27.5727 16.0565 25.7522 15.7021 23.9137 15.7021C22.0752 15.7021 20.2547 16.0565 18.5561 16.7451C16.8576 17.4337 15.3142 18.443 14.0142 19.7154C13.2331 20.4798 11.9668 20.4798 11.1858 19.7154C10.4047 18.9509 10.4047 17.7116 11.1858 16.9471C12.8572 15.3112 14.8415 14.0136 17.0254 13.1282Z"
    />
    <path
      d="M23.9137 17.6596C22.3379 17.6596 20.7774 17.9633 19.3215 18.5536C17.8656 19.1438 16.5427 20.0089 15.4284 21.0995C14.6474 21.8639 14.6474 23.1033 15.4284 23.8677C16.2095 24.6322 17.4758 24.6322 18.2569 23.8677C18.9997 23.1407 19.8817 22.5639 20.8523 22.1704C21.8229 21.777 22.8632 21.5744 23.9137 21.5744C24.9643 21.5744 26.0046 21.777 26.9752 22.1704C27.9458 22.5639 28.8277 23.1407 29.5706 23.8677C30.3516 24.6322 31.618 24.6322 32.399 23.8677C33.1801 23.1033 33.1801 21.8639 32.399 21.0995C31.2847 20.0089 29.9618 19.1438 28.5059 18.5536C27.05 17.9633 25.4896 17.6596 23.9137 17.6596Z"
    />
    <path
      d="M20.9445 24.1442C22.8076 23.3431 25.0198 23.3431 26.883 24.1442C27.8201 24.5472 28.6637 25.1344 29.3689 25.8665C30.1266 26.6531 30.0894 27.8919 29.2857 28.6335C28.482 29.3751 27.2162 29.3387 26.4585 28.5521C26.1131 28.1935 25.7095 27.9157 25.274 27.7284C24.4362 27.3682 23.3912 27.3682 22.5534 27.7284C22.1179 27.9157 21.7143 28.1935 21.3689 28.5521C20.6112 29.3387 19.3454 29.3751 18.5417 28.6335C17.738 27.8919 17.7008 26.6531 18.4585 25.8665C19.1637 25.1344 20.0073 24.5472 20.9445 24.1442Z"
    />
    <path
      d="M23.9137 33.3191C25.0183 33.3191 25.9137 32.4427 25.9137 31.3617C25.9137 30.2806 25.0183 29.4042 23.9137 29.4042C22.8091 29.4042 21.9137 30.2806 21.9137 31.3617C21.9137 32.4427 22.8091 33.3191 23.9137 33.3191Z"
    />
  </svg>
</template>
